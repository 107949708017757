
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        































.labelized {
  position: relative;
  padding: 3rem;
  border: 1px solid $c-orange;
  border-radius: 1rem;

  p {
    margin: 0;
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.labelized__label {
  position: absolute;
  top: 0;
  left: 3rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  color: $c-light;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  background-color: $c-orange;
  border-radius: 0.3rem;
  transform: translateY(-50%);
}

.labelized__text,
.labelized__title {
  margin: $spacing / 2 0 1.5rem;
}
