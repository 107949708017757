
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        









































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.cards-grid-outer {
  @include bg-color;
  &.is-midnight-darkest {
    .cards-grid__intro * {
      color: white;
    }
  }
}

.cards-grid,
[class*='cards-grid--'] {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 4;
}

.cards-grid__list,
[class*='cards-grid__list--'] {
  @extend %list-nostyle;

  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq(m) {
    display: block;

    [class*='--alternate'] & {
      display: inline;

      li:first-child {
        margin-top: $spacing * 5;
      }

      li:nth-child(odd) {
        float: left;
      }

      li:nth-child(even) {
        float: right;
      }
    }

    [class*='--alternate--reverse'] & {
      li:nth-child(odd) {
        float: right;
      }

      li:nth-child(even) {
        float: left;
      }
    }
  }
}

.cards-grid__intro,
.cards-grid__disclaimer {
  @include mq(m) {
    width: col(9);
    margin: 0 auto $spacing * 2;
    text-align: center;

    .tv-extra & {
      margin: 0 0 $spacing * 3;
    }

    [class*='--alternate'] & {
      float: right;
      width: col(5);
      margin: 0 col(1) / 2;
      text-align: left;
    }

    [class*='--alternate--reverse'] & {
      float: left;
      width: col(5);
    }
  }
}

.cards-grid__disclaimer {
  margin-top: 4rem;

  @include mq(m) {
    margin-top: -40% !important;

    [class*='--alternate'] & {
      float: left;
    }

    [class*='--alternate--reverse'] & {
      float: right;
    }
  }

  @include mq(l) {
    margin-top: -30% !important;
  }

  @include mq(xl) {
    margin-top: -20% !important;
  }
}

.cards-grid__intro__title {
  margin-top: 0;
}

.cards-grid__intro__text {
  margin-top: 2em;

  ::v-deep {
    a {
      color: $c-pink-medium;
    }
  }
}

.cards-grid__list__item-outer {
  width: 100%;

  &:not(:first-child) {
    margin-top: $spacing * 2;
  }

  @include mq(m) {
    width: 100%;

    &:not(:first-child) {
      margin-top: $spacing * 4;
    }

    [class*='--alternate'] & {
      width: col(5);
      margin: $spacing * 8 col(1) / 2 0;
    }
  }
}

.cards-grid__link {
  @extend %text-center;

  margin-top: $spacing;

  @include mq(m) {
    margin-top: $spacing * 2;
  }

  @include mq(xl) {
    margin-top: $spacing * 4;
  }
}

.cards-grid__intro__include-outer {
  @include mq($until: m) {
    margin-bottom: $spacing * 2;
  }
}

.cards-grid__intro__include {
  @extend %fw-medium;
  @extend %color-light;

  padding: $spacing / 4 $spacing / 2;
  font-size: 1.5rem;
  background-color: $c-pink-medium;
  border-radius: 3px;

  @include mq(m) {
    padding: $spacing / 4 $spacing;
  }
}

.podcasts__intro__link {
  display: block;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }

  @include mq(s) {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}

.podcasts__intro__link__badge {
  max-height: 5rem;

  @include mq(s) {
    max-height: 4rem;
  }
}
